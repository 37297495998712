<template>
  <!-- NAME[epic=报表] 日销统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="时间类型"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select v-model="form.user_id" clearable placeholder="请选择员工">
          <el-option
            v-for="(list_, index) in userlist"
            :key="index"
            :value="list_.user_id"
            :label="list_.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="handleDetail(row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="total">
      <div>销售收款合计:{{ totalAll.sale_money }}</div>
      <div>派送收款合计:{{ totalAll.driver_money }}</div>
      <div>预存款收款合计:{{ totalAll.deposit_money }}</div>
      <div>预存货收款合计:{{ totalAll.goods_money }}</div>
      <div>清欠收款合计:{{ totalAll.clear_money }}</div>
      <div>兑奖现金合计:{{ totalAll.prize_amount }}</div>
      <div>预存退款合计:{{ totalAll.back_money }}</div>
      <div>收支合计:{{ totalAll.total_amount }}</div>
    </div>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import { day_n } from '@/utils/Time'
  export default {
    name: 'DailySale',
    components: {},
    data() {
      return {
        form: {
          // 时间类型
          time_type: '1',
          start_time: '',
          end_time: '',
        },
        time: [],
        userlist: [],

        loading: false,
        timeTypeSelect: [
          {
            id: '2',
            name: '下单时间',
          },
          {
            id: '1',
            name: '到货时间',
          },
          {
            id: '3',
            name: '结清时间',
          },
        ],

        list: [],
        total: 0,
        totalAll: {},

        columns: [
          {
            order: 1,
            label: '员工',
            prop: 'user_name',
            width: '',
          },
          {
            order: 1,
            label: '部门',
            prop: 'depart_name',
            width: '',
          },
          {
            order: 2,
            label: '+销售收款',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 3,
            label: '+派送收款',
            prop: 'driver_money',
            width: '',
          },
          {
            order: 4,
            label: '+预存款收款',
            prop: 'deposit_money',
            width: '',
          },
          {
            order: 5,
            label: '+预存货收款',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 6,
            label: '+清欠收款',
            prop: 'clear_money',
            width: '',
          },
          {
            order: 7,
            label: '-兑奖现金',
            prop: 'prize_amount',
            width: '',
          },
          {
            order: 7,
            label: '-兑付现金',
            prop: 'cash_amount',
            width: '',
          },
          {
            order: 7,
            label: '-预存退款',
            prop: 'back_money',
            width: '',
          },
          {
            order: 7,
            label: '个人收支合计',
            prop: 'total_amount',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.getuserlist()
      this.initTime()
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      initTime() {
        let a = day_n(1)
        this.time = [a[1] + ' 00:00:00', a[1] + ' 23:59:59']
        this.form.start_time = a[1] + ' 00:00:00'
        this.form.end_time = a[1] + ' 23:59:59'
      },
      async fetchData() {
        this.loading = true
        let { data, totalCount, ext } = await postAction(
          '/reportAdmin/operate/day-form-index',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.totalAll = ext
        this.loading = false
      },
      // 员工下拉
      getuserlist() {
        postAction('/baseAdmin/user/index', { pageSize: -1 })
          .then((res) => {
            this.userlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleQuery() {
        this.fetchData()
      },
      handleExport() {},
      handleDetail(row) {
        console.log(row)
        this.$router.push({
          name: 'UserDetail',
          params: { ...this.form, ...row },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .total {
    display: flex;
    padding: 15px 20px;
    justify-content: space-around;
  }
</style>
